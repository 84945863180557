<template>
  <div>
    <iframe 
      id="panda-player"
      :src="source"
      style="border:none;" 
      :height="height"
      allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;fullscreen"
      allowfullscreen=true>
    </iframe>
  </div>
</template>

<script>
import notify from "@/services/libs/notificacao";

export default {
  props: ["source", "porcentagem"],
  data() {
    return {
      height: 500,
      options: {
        muted: false,
        autoplay: true,
      },
      playerReady: false,
      t: "",
      panda_ended: false,
    };
  },
  methods: {
    initScriptPandaVideo() {
      if (!document.getElementById("fkdjshfkjsdhfkjdsh")) {
        let pandaScript = document.createElement("script");
        pandaScript.setAttribute(
          "src",
          "https://player.pandavideo.com.br/api.v2.js"
        );
        pandaScript.setAttribute("id", "fkdjshfkjsdhfkjdsh");
        document.head.appendChild(pandaScript);
      }
    },

    loadPandaVideo() {
      window.onPandaPlayerApiLoad = function () {
        // eslint-disable-next-line no-undef
        const player = new PandaPlayer('panda-player', {
          onReady: () => {
            const playButton = document.getElementById('play');
            const pauseButton = document.getElementById('pause');
            const currentTimeElement = document.getElementById('current-time');
            const statusElement = document.getElementById('status');

            if (playButton) {
              playButton.onclick = function () {
                player.play();
              }
            }

            if (pauseButton) {
              pauseButton.onclick = function () {
                player.pause();
              }
            }

            if (currentTimeElement) {
              setInterval(() => {
                currentTimeElement.innerHTML = 'Current time: ' + player.getCurrentTime().toFixed(2) + ' seconds';
              }, 1000);
            }

            player.onEvent(function ({ message }) {
              if (statusElement) {
                if (message === 'panda_pause') {
                  statusElement.innerHTML = "Status: paused";
                } else if (message === 'panda_play') {
                  statusElement.innerHTML = "Status: playing";
                }
              }
            });
          }
        });
      }
      window.onPandaPlayerApiLoad();
    },

    controlPlayer(type) {
      if (type === "play") {
        this.$refs.player.play();
      } else if (type === "concluiu") {
        this.$root.$emit("liberaBtn");
      } else if (type === 'calcula') {
        this.$root.$emit('calculaBtn');
      } else if (type === "next") {
        this.$root.$emit("liberaBtnNext");
      } else {
        this.$refs.player.pause();
      }
    },
    async calculatePercentage(type) {
      // eslint-disable-next-line no-undef
      this.player = new PandaPlayer("panda-player");

      setTimeout(() => {
        const current = parseInt(this.player.currentTime, 10);
        const total = parseInt(this.player.duration, 10);
        const percentage = parseInt((current / total) * 100, 10);

        if (percentage >= parseInt(this.porcentagem, 10) || this.panda_ended) {
          this.panda_ended = false;
          this.controlPlayer(type);
        } else {
          notify("erro", this.$t('player.errors_players.msg3'));
        }
      }, 500);
    },
  },
  mounted() {
    this.initScriptPandaVideo();

    this.loadPandaVideo();

    this.$root.$on("clicouConcluir", (data) => {
      if (data === "panda") {
        this.calculatePercentage("concluir");
      }
    });

    this.$root.$on("clicouNext", (data) => {
      if (data === "panda") {
        this.calculatePercentage("next");
      }
    });

    this.$root.$on("calcula", (data) => {
      if (data === "panda") {
        this.calculatePercentage("calcula");
      }
    });
  },
  beforeDestroy() {
    document.getElementById("fkdjshfkjsdhfkjdsh").remove();
  },
};
</script>

<style>
.toZindex .playerVimeo {
  z-index: 99999999999999999;
}
</style>